import styled from "styled-components";


export const LeftContainer = styled.div`
  height: calc(100% - 48px);
  width: 68px;
  background-color: white;
  padding: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 12;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.19), 0px 6px 12px -2px rgba(0, 0, 0, 0.04); 
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HasDelivery = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #30A46C;
  padding: 1.5px 6px;
  position: absolute;
  border-radius: 99px;
  right: -6px;
  top: -6px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;