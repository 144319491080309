import React, { useEffect, useState } from 'react';
import { Button, Tag } from '@stonelog/stonelog-design-system';
import { useSelector, useDispatch } from "react-redux";
import { hubsSelector } from '../../../../../../../../../features/senninha/hubsSlice';
import { manageSelectedRelocatePins, resetSelectedRelocatePins, routesManagementSelector } from '../../../../../../../../../features/senninha/routesManagementSlice';

import * as S from './styles';


const PreDeliveryRoutes = ({
    setNewWorkFlow,
    setRoutes
}) => {    
    const dispatch = useDispatch()

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        selectedRelocatePins
    } = useSelector(routesManagementSelector);
    
    const getTotalOss = () => selectedRelocatePins.reduce((sum, item) => sum += item.oss.length, 0)

    const managePinRemoval = (pin) => {
        dispatch(manageSelectedRelocatePins(pin))
    }

    const handleSaveClick = () => {
        setRoutes((p) => [...p, {
            type: 'preDelivery',
            angel: '',
            pins: selectedRelocatePins,
            ossAmount: getTotalOss(),
            polo: selectedHubInfos.children,
        }])
        setNewWorkFlow(false)
    }

    return (
        <S.RealocateContainer>
            <S.RealocateInfos>
                <p>
                    Total de pins selecionados
                </p>
                <span>
                    {selectedRelocatePins.length}
                </span>
            </S.RealocateInfos>
            <S.RealocateInfos>
                <p>
                    Total de OS
                </p>
                <span>
                    {getTotalOss()}
                </span>
            </S.RealocateInfos>
            <S.RealocateTags>
            {selectedRelocatePins.map(
                (item) => item.oss.map(({ id_os }, i) => 
                    (item.tipo !== 'any' && i === 0 || item.tipo === 'any') &&
                        <S.TagArea
                            key={i}
                            isBlocked={false}
                        >
                            <Tag
                                text={item.tipo === 'any' ? id_os.toString() : item.id_atendimento.toString()}
                                onClose={() => managePinRemoval(item)}
                            />
                        </S.TagArea>
                )
            )}
            </S.RealocateTags>

            <S.Footer>
                <Button
                    size='small'
                    onClick={() => {
                        setNewWorkFlow(false)
                        dispatch(resetSelectedRelocatePins())
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    size='small'
                    type='primary'
                    disabled={!selectedRelocatePins.length}
                    onClick={handleSaveClick}
                >
                    Salvar
                </Button>
            </S.Footer>
        </S.RealocateContainer>
    )
};

export default PreDeliveryRoutes;
