const config = {
  logisticsUrl:
    process.env.REACT_APP_LOGISTICS_API_URL ||
    "https://stonelog.stone.com.br/api/gps",
  garagemToken: process.env.REACT_APP_GARAGEM_TOKEN,
  xavierToken: process.env.REACT_APP_XAVIER_TOKEN,
  header: "setHeader(Content-Type, application/json)",
  mapsKey: process.env.REACT_APP_MAPS_KEY,
  logToken: process.env.REACT_APP_STONELOG_TOKEN,
  hunterUrl:
    process.env.REACT_APP_HUNTER_API_URL ||
    "https://stonelog.stone.com.br/api/hunter",
  wsKey: process.env.REACT_APP_WS_URL,
  anyUrl: process.env.REACT_APP_ANY_URL
};

export default config;
