import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Checkbox, Drawer, Tag } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { capitalizeMultipleFirstLetters } from '../../../../../../../../../utils/formatter';
import { postSimulatePreDeliveries, removeSelectedPreDeliveryPins, resetSelectedRelocatePins, routesManagementSelector } from '../../../../../../../../../features/senninha/routesManagementSlice';
import Simulation from './Simulation';
import { setMapWorkflow } from '../../../../../../../../../features/senninha/pinsSlice';
import { PreDeliverySimulationProvider } from '../../../../../../../../../context/preDeliverySimulationContext';
import { hubsSelector } from '../../../../../../../../../features/senninha/hubsSlice';
import machineError from "@assets/imgs/machine-error.svg";
import warning from "@assets/icons/Warning.svg";
import { Tooltip } from 'antd';
import { getValidateTechnician, preDeliveryFormSelector, resetValidationTechnicianStates } from '../../../../../../../../../features/senninha/preDeliveryFormSlice';

import * as S from './styles';


const PreDeliveryComponent = ({ onClose, setActiveKeyTab }) => {
    // const [checkedList, setCheckedList] = useState([])
    const [checkboxOptions, setCheckboxOptions] = useState([])
    // const checkAll = checkboxOptions.length === checkedList.length && checkedList.length
    // const indeterminate = checkedList.length > 0 && checkedList.length < checkboxOptions.length

    const dispatch = useDispatch();

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        selectedPreDeliveryPins,
        statusSimulatePreDeliveries,
        errorSimulatePreDeliveries
    } = useSelector(routesManagementSelector);

    const {
        technicianValidationData,
        statusValidateTechnician,
        errorValidateTechnician,
        retryTechnicianWithLastPayload
    } = useSelector(preDeliveryFormSelector)

    const handleDelete = (id_atendimento) => {
        dispatch(removeSelectedPreDeliveryPins(id_atendimento))
    }

    const handleSimulation = () => {
        const payload = selectedPreDeliveryPins.map((item) => ({
            "oss": item.oss.map(({ id_os, servico }) => ({ idOs: id_os.toString(), servico })),
            "poloName": selectedHubInfos.children,
            "referenceKey": item.id_referencia_visita,
            "deliveryAddress": {
                "nick": item.cliente,
                "zipCode": item.cep,
                "city": item.oss[0].cidade,
                "neighborhood": item.oss[0].referencia,
                "addressNumber": item.numero_endereco,
                "address": item.endereco,
                "countryState": item.uf,
                "latitude": item.lat,
                "longitude": item.lng,
                "workingHours": null,
                "reference": null,
                "complement": item.complemento,
                "country": "BRASIL"
            }
        }))

        dispatch(postSimulatePreDeliveries(payload))
    }

    // const onCheckAllChange = (e) =>
    //     setCheckedList(e.target.checked ? checkboxOptions : [])   

    // const handleChangeCheckbox = (checkedValue) => 
    //     setCheckedList((p) => checkedList.find((item) => item === checkedValue) ? 
    //         p.filter((item) => item !== checkedValue)
    //         : [...p, checkedValue]
    //     )

    useEffect(() => {
        dispatch(setMapWorkflow('routesDrawer'))

        return () => {
            dispatch(resetValidationTechnicianStates())
        };
    }, [])

    // useEffect(() => {
    //     if (selectedPreDeliveryPins?.length) 
    //         setCheckboxOptions(selectedPreDeliveryPins.reduce((res, item) => {
    //             const unninstallOss = item.oss.filter(({ servico }) => servico === 'DESINSTALAÇÃO')
    //             if (unninstallOss.length) return [...res, item.id]
    //             return res
    //         }, []))
    // }, [selectedPreDeliveryPins])

    useEffect(() => {
        if (statusSimulatePreDeliveries === 'fulfilled') {
            dispatch(resetSelectedRelocatePins())
            dispatch(setMapWorkflow('routesDrawer'))
        }
    }, [statusSimulatePreDeliveries])

    return (
        statusSimulatePreDeliveries === 'fulfilled' && ['idle', 'fulfilled'].includes(statusValidateTechnician) ? (
            <Simulation
                onClose={onClose}
                setActiveKeyTab={setActiveKeyTab}
            />
        ) : (
            <>
                <Drawer.Body>
                    {statusSimulatePreDeliveries === 'pending' || statusValidateTechnician === 'pending' ? (
                        <Drawer.Loading
                            message="Aguarde um momento, estamos simulando as deliveries..."
                        />
                    ) : (
                        statusValidateTechnician === 'rejected' ? (
                            <Drawer.Response
                                element={<img src={warning} />}
                                message={errorValidateTechnician}
                            />
                        ) : (
                            statusSimulatePreDeliveries === 'idle' &&
                                selectedPreDeliveryPins.length ?
                                <>
                                    {/* {!!checkboxOptions.length && 
                                                <S.HeaderContainer>
                                                    <div style={{ flex: 1 }}>
                                                        <S.HeaderTitle>
                                                            {`Coletar OS no polo para todos os atendimentos de desinstalação (${checkboxOptions.length})`}
                                                        </S.HeaderTitle>
                                                        <S.HeaderSubTitle>
                                                            O prestador  irá primeiro no polo, em seguida ao cliente e depois retornará ao polo novamente.
                                                        </S.HeaderSubTitle>
                                                    </div>
                                                    <S.CheckBoxAllArea>
                                                        <Checkbox
                                                            indeterminate={indeterminate}
                                                            onChange={onCheckAllChange}
                                                            checked={checkAll}
                                                            size={24}
                                                        />
                                                    </S.CheckBoxAllArea>
                                                </S.HeaderContainer>
                                            }
     */}
                                    <Alert
                                        description="Você vai precisar entrar em contato com a %b%Loggi (4020-1460)%b% para definir o ponto de retorno em casos de: %b%Ineficiência, Troca%b% ou %b%Desinstalação.%b%"
                                        type="info"
                                    />

                                    {selectedPreDeliveryPins.map((pin, i) => (
                                        <Drawer.Row key={i} style={{ display: 'block' }}>
                                            <div style={{ display: 'flex' }}>
                                                <Drawer.RowText
                                                    text={pin.cliente}
                                                    description={`${capitalizeMultipleFirstLetters(pin.servico)} | OS ${pin.oss.map(({ id_os }) => `${id_os}`)} `}
                                                />
                                                <Drawer.RowActions>
                                                    <GingaIcon name="trash" color="#E6171E" size={20} onClick={() => handleDelete(pin.id_atendimento)} />
                                                </Drawer.RowActions>
                                            </div>

                                            {/* {checkboxOptions.includes(pin.id) && 
                                                        <S.CheckboxContainer isActive={checkedList.includes(pin.id)}>
                                                            <S.CheckboxArea>
                                                                <Checkbox
                                                                    size={24}
                                                                    value={pin.id}
                                                                    checked={checkedList.includes(pin.id)}
                                                                    onChange={(e) => handleChangeCheckbox(e.target.value)}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                    }}
                                                                />
                                                                <S.CheckboxText>
                                                                    Coletar OS no polo
                                                                </S.CheckboxText>
                                                            </S.CheckboxArea>
    
                                                            <Tooltip
                                                                title="O prestador irá primeiro no polo, em seguida ao cliente e depois retornará ao polo novamente."
                                                                placement="topLeft"
                                                                overlayClassName="ginga-tooltip ginga-tooltip-timelime ginga-tooltip-top-right"
                                                            >
                                                                <GingaIcon name="round-info" color="#A6AEBA" size={20}/>
                                                            </Tooltip>
                                                        </S.CheckboxContainer>
                                                    } */}
                                        </Drawer.Row>
                                    ))}
                                </>
                                :
                                statusSimulatePreDeliveries === 'rejected' ?
                                    <Drawer.Response
                                        element={<img src={machineError} />}
                                        message={errorSimulatePreDeliveries ? errorSimulatePreDeliveries : "Houve um erro!"}
                                    /> : <Drawer.Empty />
                        )
                    )}
                </Drawer.Body>

                {statusValidateTechnician === 'rejected' ?
                    <Drawer.Footer>
                        <Button
                            type='primary'
                            width='100%'
                            height='52px'
                            size='large'
                            onClick={() => {
                                dispatch(getValidateTechnician(retryTechnicianWithLastPayload))
                            }}
                        >
                            Tentar novamente
                        </Button>
                        <Button
                            width='100%'
                            height='52px'
                            size='large'
                            onClick={() => {
                                dispatch(resetValidationTechnicianStates())
                            }}
                        >
                            Voltar
                        </Button>
                    </Drawer.Footer>
                    :
                    (!!selectedPreDeliveryPins.length && statusSimulatePreDeliveries === 'idle' && statusValidateTechnician !== 'pending') &&
                    <Drawer.Footer>
                        <Button
                            type='primary'
                            width='100%'
                            height='52px'
                            size='large'
                            onClick={handleSimulation}
                        >
                            Simular
                        </Button>
                    </Drawer.Footer>
                }
            </>
        )
    )
};

const PreDelivery = (props) => (
    <PreDeliverySimulationProvider>
        <PreDeliveryComponent {...props} />
    </PreDeliverySimulationProvider>
)

export default PreDelivery;
