export const personIconSvg = `
       <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="19" fill="white" stroke="rgb(0, 142, 90)" stroke-width="3" filter="url(#shadow)" />
          <circle cx="24" cy="24" r="12" fill="rgb(0, 142, 90)" />
          <animateMotion repeatCount="indefinite" dur="4s">
            <mpath href="#path" />
          </animateMotion>
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
              <feOffset in="blur" dx="2" dy="2" result="offsetBlur" />
              <feMerge>
                <feMergeNode in="offsetBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </svg>
    `;