import { Button } from "@stonelog/stonelog-design-system";
import styled from "styled-components";


export const InsideDrawerContent = styled.div`
  position: absolute;
  left: 68px;
  bottom: 0;
  height: calc(100% - 48px);
  z-index: 1;
`;

export const ButtonStyled = styled(Button)`
  > div {
    margin-top: -2px;
  }
`;