import { useContext } from 'react';
import { PreDeliverySimulationContext } from '../context/preDeliverySimulationContext';


export const usePreDeliverySimulationContext = () => {
  const context = useContext(PreDeliverySimulationContext);

  if (!context) {
    throw new Error(
      'PreDeliverySimulationContext must be used within an PreDeliverySimulationProvider',
    );
  }

  return context;
};
