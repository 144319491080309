import React from "react";
import { HiOutlineLogout } from "react-icons/hi";
import { getCookie } from "@helper";
import logoIcon from "@assets/icons/logo-icon.svg";
import { HamburguerIcon } from "@assets/icons/HamburguerIcon";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  useAuthContext,
  useProvideAuth,
} from "@stonelog/stonelog-auth-components";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { hubsSelector } from "../../features/senninha/hubsSlice";
import { getAllPinsData } from "../../features/senninha/pinsSlice";


import * as S from "./styles";

export const Header = (props) => {
  const auth = useAuthContext();
  const userInfos = auth.getUser();
  const userOptions = useProvideAuth();

  const dispatch = useDispatch();

  const {
    selectedHubInfos
} = useSelector(hubsSelector);

  const logout = () => {
    document.cookie = "token=;0;path=/";
    window.location.href =
      window.location.protocol + "//" + window.location.host;
  };

  return (
    <S.HeaderTopo loginScreen={props.loginScreen}>
      <S.HeaderLeft>
        {props.showHamburguerIcon ? (
          <S.Hamburguer>
            <HamburguerIcon
              size={24}
              color={"#fff"}
              onClick={() => {
                props.setShowHamburguer(!props.showHamburguer);
              }}
            />
          </S.Hamburguer>
        ) : (
          <></>
        )}
        <S.HeaderTitulo>
          <Link to="/">{`Dash Angels ${
            props.titlePage ? props.titlePage : ""
          }`}</Link>
        </S.HeaderTitulo>
      </S.HeaderLeft>
      <img src={logoIcon} alt="Logo Stone" />
      <S.HeaderRight>
        <>
          <div>
            <GingaIcon
              name="action-refresh"
              color="#fff"
              size={24}
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(getAllPinsData({
                provider: selectedHubInfos?.children // children é o valor do hub/polo
            }))}
            />
          </div>
          {userInfos.email ? (
            <S.BoxUser>
              <span>Olá, {userInfos.name}</span>
              <strong>
                {userInfos.name?.split(" ")[0][0] +
                  userInfos.name?.split(" ")[1][0]}
              </strong>

              <S.ButtonUser type="button" onClick={() => userOptions.logout()}>
                <HiOutlineLogout size={20} color="#E6171E" />
                <p>Sair</p>
              </S.ButtonUser>
            </S.BoxUser>
        ) : (
          <></>
        )}

        </>
      </S.HeaderRight>
    </S.HeaderTopo>
  );
};
