import React, { useEffect, useMemo, useState } from 'react';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import { Button, Drawer, FormInput, FormTextArea, Loading } from '@stonelog/stonelog-design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { installationSchema } from './schemas/schemas';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';
import { getValidateSerial, preDeliveryFormSelector, resetValidationSerialStates } from '../../../../../../../../../../features/senninha/preDeliveryFormSlice';
import { useSelector, useDispatch } from 'react-redux';

import * as S from '../styles';

const InstallationForm = ({
    os,
    infoOs,
    posRecommendation,
    obsDefaultValue,
    inputSerial,
    isInMultiGroupForm,
    handleSaveClient
}) => {
    const { 
        serialValidationData,
        statusValidateSerial,
        errorValidateSerial
    } = useSelector(preDeliveryFormSelector)

    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext()

    const dispatch = useDispatch()

    const initialFormData = useMemo(() => formsSimulationIdData[os]?.formData ?? {
        osInfo: infoOs,
        inputSerial,
        notes: obsDefaultValue,
    }, [formsSimulationIdData, os, infoOs, inputSerial, obsDefaultValue]);

    const {
        control,
        formState: { isValid, isValidating, errors },
        getValues,
        trigger,
        watch
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(installationSchema(isInMultiGroupForm)),
        defaultValues: initialFormData
    });

    const serial = watch('inputSerial');
    const isSuccess = serialValidationData?.tipo === 'success' && statusValidateSerial === 'fulfilled';
    const isError = serialValidationData?.tipo === 'error' || errorValidateSerial;
    const isLoading = statusValidateSerial === 'pending';

    const handleValidateSerial = () => {
        if (!isLoading) {
            if (errorValidateSerial) {
                dispatch(resetValidationSerialStates());
            }
            if (serial) {
                dispatch(getValidateSerial({
                    terminal_entrada: serial,
                    os
                }));
            }
        }
    }

    const handleSave = () => {
        setFormsSimulationIdData((p) => ({
            ...p,
            [os]: {
                ...p[os],
                isSubmitted: true,
                formData: getValues(),
            }
        }))
        if (!isInMultiGroupForm) handleSaveClient()
    }

    useEffect(() => {
        dispatch(resetValidationSerialStates());
        trigger()

        return () => {
            if (isInMultiGroupForm) handleSave();
            dispatch(resetValidationSerialStates());
          };
    }, [])

    useEffect(() => {
        if (!Object.keys(errors).length)
            setFormsSimulationIdValidations({
                ...formsSimulationIdValitations,
                [os]: {
                    ...formsSimulationIdValitations[os],
                    isValid: isValid && isSuccess,
                }
            })
    }, [isValidating, errors, isSuccess])

    useEffect(() => {
        if (!serial && isError){
            dispatch(resetValidationSerialStates());
        }
    }, [serial, isError])

    return (
        <S.FormArea>
            <S.ClientFormBlock>
                <FormTextArea
                    control={control}
                    name="osInfo"
                    autoSize
                    label="Info da OS"
                    disabled
                />
                <FormInput
                    control={control}
                    name="inputSerial"
                    label="Serial de entrada"
                    placeholder="Bipe ou digite aqui"
                    suffixIcon={isLoading? 
                        <Loading size={20} borderWidth={2}/> 
                        : <GingaIcon color="#767F8D" name="code-bar-scanner" size={24}/>
                    }
                    disabled={isLoading}
                    supportText={
                        isError ? 
                        errorValidateSerial?.mensagem || 'error' 
                        : posRecommendation
                    }
                    size="small"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleValidateSerial()
                    }}
                    {...(isError ? { status: 'error' } : {})}
                />
                {!isInMultiGroupForm && (
                    <FormTextArea
                        control={control}
                        name="notes"
                        label="Observações"
                        rows={3}
                        style={{ resize: 'none' }}
                    />
                )}
            </S.ClientFormBlock>

            {!isInMultiGroupForm &&
                <Drawer.Footer
                    style={{ margin: '0 -24px' }}
                >
                    <Button
                        type='primary'
                        width='100%'
                        height='52px'
                        size='large'
                        onClick={handleSave}
                        disabled={!isValid || !isSuccess}
                    >
                        Salvar
                    </Button>
                </Drawer.Footer>
            }
        </S.FormArea>
    )
};

export default InstallationForm;
