import React, { useState } from 'react';
import { Button, Drawer, Select, Tag } from '@stonelog/stonelog-design-system';
import { GingaIcon } from '@stonelog/stonelog-ginga-icons';
import RelocateRoutes from './Tabs/RoutePlanning/RelocateRoutes';
import RoutePlanning from './Tabs/RoutePlanning';
import { useSelector } from 'react-redux';
import PreDelivery from './Tabs/PreDelivery/PreDelivery';
import { routesManagementSelector } from '../../../../../../../features/senninha/routesManagementSlice';

import * as S from './styles';


const RoutesDrawer = ({
    onClose,
    open,
    showNotification
}) => {
    const [activeKeyTab, setActiveKeyTab] = useState('1')
    const {
        selectedPreDeliveryPins,
    } = useSelector(routesManagementSelector);

    return (
        <Drawer.Root
            placement='right'
            open={open}
            onClose={() => onClose()}
            mask={false}
            title={
                <Drawer.Header
                    title='Gestão de Rotas'
                    subTitle='Aqui você pode realocar, desalocar, trocar rotas e solicitar deliveries.'
                />
            }
        >
            <Drawer.Tabs
                activeKey={activeKeyTab}
                onChange={setActiveKeyTab}
                items={[
                    {
                    key: '1',
                    label: 'Roteirização',
                    children: (
                        <RoutePlanning
                            onClose={onClose}
                            showNotification={showNotification}
                            activeKeyTab={activeKeyTab}
                            setActiveKeyTab={setActiveKeyTab}
                        />
                    ),
                    },
                    {
                    key: '2',
                    label: 
                        <S.PreDeliveryLabel>
                            lista pré-delivery 
                            {selectedPreDeliveryPins?.length ?
                                <S.PreDeliveryBadge>{` ${selectedPreDeliveryPins.length}`}</S.PreDeliveryBadge>
                                : ''
                            }
                        </S.PreDeliveryLabel>,
                    children: (
                        <PreDelivery 
                            onClose={onClose}
                            setActiveKeyTab={setActiveKeyTab}
                        />
                    ),
                    },
                ]}
            />
        </Drawer.Root>
    )
};

export default RoutesDrawer;
