import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { manageSelectedRelocatePins, routesManagementSelector } from '../../../../../../../../../features/senninha/routesManagementSlice';
import { Input, Tag } from '@stonelog/stonelog-design-system';
import { applyFilter, pinsSelector, setBuildFilters } from '../../../../../../../../../features/senninha/pinsSlice';
import { useDispatch } from 'react-redux';

import * as S from '../RoutePlanning/styles';


const AddNewPins = () => {
    const dispatch = useDispatch();

    const {
        filters,
    } = useSelector(pinsSelector);

    const {
        selectedRelocatePins,
        selectedPreDeliveryPins
    } = useSelector(routesManagementSelector);

    const getTotalOss = () => selectedRelocatePins.reduce((sum, item) => sum += item.oss.length, 0)
    
    const togglePinsAny = (visibility) => {
        const idx = filters.findIndex(
            (obj) => obj.field === 'tipo' && obj.value === 'adquirencia' && obj.drawer === 'gestaoRotas'
        );

        let filtersAux = [...filters]

        if (visibility === 'show' && idx !== -1) 
            filtersAux.splice(idx, 1);
        if (visibility === 'hide' && idx === -1)
            filtersAux = [
                ...filters, 
                { field: 'tipo', value: 'adquirencia', drawer: 'gestaoRotas' }
            ]

        dispatch(setBuildFilters(filtersAux))
        dispatch(applyFilter())
    }

    const managePinRemoval = (pin) => {
        dispatch(manageSelectedRelocatePins(pin))
    }

    useEffect(() => {
        selectedPreDeliveryPins.forEach((dataPin) => {
            dispatch(manageSelectedRelocatePins(dataPin))
        });
        togglePinsAny('hide')

        return (() => {
            togglePinsAny('show')
            selectedPreDeliveryPins.forEach((dataPin) => {
                dispatch(manageSelectedRelocatePins(dataPin))
            });
        })
    }, [])

    return (
        <S.BodyMiddleContainer>
            <Input
                label="Função"
                value="Pré-delivery"
                size="small"
                disabled
                style={{ margin: '4px 0 8px 0' }}
            />
            <S.RealocateContainer style={{ border: 'none' }}>
                <S.RealocateInfos>
                    <p>
                        Total de pins selecionados
                    </p>
                    <span>
                        {selectedRelocatePins.length}
                    </span>
                </S.RealocateInfos>
                <S.RealocateInfos>
                    <p>
                        Total de OS
                    </p>
                    <span>
                        {getTotalOss()}
                    </span>
                </S.RealocateInfos>
                <S.RealocateTags>
                {selectedRelocatePins.map(
                    (item) => item.oss.map(({ id_os }, i) => 
                        (item.tipo !== 'any' && i === 0 || item.tipo === 'any') &&
                            <S.TagArea
                                key={i}
                                isBlocked={false}
                            >
                            <Tag
                                text={item.tipo === 'any' ? id_os.toString() : item.id_atendimento.toString()}
                                onClose={() => managePinRemoval(item)}
                            />
                            </S.TagArea>
                    )
                )}
                </S.RealocateTags>
            </S.RealocateContainer>
        </S.BodyMiddleContainer>
    )
};

export default AddNewPins;
