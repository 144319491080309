import React, { createContext, useState } from 'react';

export const PreDeliverySimulationContext = createContext(
  {},
);

export const PreDeliverySimulationProvider = ({ children }) => {
  const [formsSimulationIdData, setFormsSimulationIdData] = useState()
  const [formsSimulationIdValitations, setFormsSimulationIdValidations] = useState()

  return (
    <PreDeliverySimulationContext.Provider
      value={{
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
      }}
    >
      {children}
    </PreDeliverySimulationContext.Provider>
  );
};
