import styled from "styled-components";


export const AllDeliveryDrawerContent = styled.div`
  width: 100%;
  /* padding-top: 8px; */
`;

export const HeaderDelivery = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const DeliveryTopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  border-bottom: 1px solid rgba(48, 55, 66, 0.1);
`;

export const DeliveryMiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(48, 55, 66, 0.1);
  cursor: pointer;
`;

export const DeliveryMiddleLeftContent = styled.div`
  display: flex;
  gap: 8px;

  p{
    color: var(--Content-Neutral-High, #303742);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 21.75px */
    letter-spacing: -0.15px;
  }
  span{
    color: #767F8D;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 18.85px */
    letter-spacing: -0.13px;
  }
`;

export const DeliveryMiddleRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const HeaderPopover = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color:  #303742;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
  }

  svg {
    cursor: pointer;
  }
`;

export const SubTitlesPopover = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  p {
    color: #767F8D;
    font-size: 15px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.15px;
    margin-bottom: 0.5rem !important;
  }

  > div:last-child {
    p { 
      margin: 0.75rem 0 !important;
    }

    .ant-select-selection-overflow-item {
      display: none;

      &:first-child {
        display: block !important;
        color: #767F8D;
        font-size: 15px;
        line-height: 145%;
        letter-spacing: -0.15px;
      }
    }
  }

`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TagCustom = styled.div`
  color: ${(props) => props.isActive ? "#008E5A" : "#303742"};
  border: 2px solid ${(props) => props.isActive ? "#008E5A" : "transparent"};
  background-color: ${(props) => props.isActive ? "#00A8681A" : "rgba(118, 127, 141, 0.10)"};
  font-size: 13px;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
  letter-spacing: -0.13px;

  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
`;

export const FooterButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
`;

export const AllBodyContainer = styled.div`
  p {
    color: var(--Content-Neutral, #767F8D);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.15px;
  }
  span {
    color: var(--Content-Neutral-High, #303742);
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.15px;
  }

`;

export const BodyTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BodyTopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AlertArea = styled.div`
  padding: 16px 0;
`;

export const EmptyDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95%;
  padding: 1.7rem;
  gap: 12px;


  p{
    color: var(--Content-Neutral-High, #303742);
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.15px;
  }
`;