import React, { useEffect, useMemo } from 'react';
import { FormInput, FormTextArea, FormSwitch, Drawer, Button } from '@stonelog/stonelog-design-system';
import { usePreDeliverySimulationContext } from '../../../../../../../../../../hooks/usePreDeliverySimulationContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uninstallationSchema } from './schemas/schemas';

import * as S from '../styles';


const UninstallationForm = ({ 
    os,
    infoOs,
    outputSerial,
    obsDefaultValue,
    isInMultiGroupForm,
    handleSaveClient
}) => {
    const {
        formsSimulationIdData,
        setFormsSimulationIdData,
        formsSimulationIdValitations,
        setFormsSimulationIdValidations
    } = usePreDeliverySimulationContext()

    const initialFormData = useMemo(() => formsSimulationIdData[os]?.formData ?? {
        osInfo: infoOs,
        outputSerial,
        notes: obsDefaultValue
    }, [formsSimulationIdData, os, infoOs, outputSerial, obsDefaultValue]);

    const {
        control,
        formState: { isValid, isValidating, errors },
        getValues,
        trigger,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(uninstallationSchema(isInMultiGroupForm)),
        defaultValues: initialFormData
    });


    const handleSave = () => {
        setFormsSimulationIdData((p) => ({
            ...p,
            [os]: {
                ...p[os],
                isSubmitted: true,
                formData: getValues(),
            }
        }))
        if (!isInMultiGroupForm) handleSaveClient()
    }

    useEffect(() => {
        trigger()

        return () => {
            if (isInMultiGroupForm) handleSave();
        };
    }, [])

    useEffect(() => {
        if (!Object.keys(errors).length)
            setFormsSimulationIdValidations({
                ...formsSimulationIdValitations,
                [os]: {
                    ...formsSimulationIdValitations[os],
                    isValid,
                }
            })
    }, [isValidating, errors])

    return (
        <S.FormArea>
            <S.ClientFormBlock>
                <FormTextArea
                    control={control}
                    name="osInfo"
                    autoSize
                    label="Info da OS"
                    disabled
                />
                <FormInput
                    control={control}
                    name="outputSerial"
                    label="Serial de saída"
                    placeholder="Bipe ou digite aqui"
                    size="small"
                    disabled
                />
                {!isInMultiGroupForm && (
                    <FormTextArea
                        control={control}
                        name="notes"
                        label="Observações"
                        rows={3}
                        style={{ resize: 'none', marginTop: 8 }}
                    />
                )}
            </S.ClientFormBlock>

            {!isInMultiGroupForm &&
                <Drawer.Footer
                    style={{ margin: '0 -24px' }}
                >
                    <Button
                        type='primary'
                        width='100%'
                        height='52px'
                        size='large'
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Salvar
                    </Button>
                </Drawer.Footer>
            }
        </S.FormArea>
    )
};

export default UninstallationForm;
