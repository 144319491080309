import styled from "styled-components";


export const BodyTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 12px 24px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

export const PreDeliveryLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const PreDeliveryBadge = styled.div`
  width: fit-content;
  height: 22px;
  padding: 4px;
  background-color: #E6171E;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  border-radius: 8px;
  margin-left: 8px;
`;