import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'options';
const GET_OPTIONS = 'options/getOptions';

const initialState = {
  defects: [],
  solutions: [],
  supplies: [],
  status: 'idle',
  error: null,
};

export const getOptions = createAsyncThunk(GET_OPTIONS, async (_, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/atendimento/senninha/busca_opcoes`);

    return res.data;
  } catch (err) {
    return rejectWithValue(err.response.data || err.response);
  }
});

export const optionsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    resetOptions: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getOptions.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getOptions.fulfilled, (state, action) => {
        state.defects = action.payload.defects;
        state.solutions = action.payload.solutions;
        state.supplies = action.payload.supplements;
        state.status = 'fulfilled';
      })
      .addCase(getOptions.rejected, (state, action) => {
        state.defects = [];
        state.solutions = [];
        state.supplies = [];
        state.status = action.payload;
        state.error = 'rejected';
      });
  },
});

export const {
  resetOptions
} = optionsSlice.actions

export const optionsSelector = (state) => state.optionsSlice;

export default optionsSlice.reducer;
