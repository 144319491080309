import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appConfig from '../../consumingApi/services/appConfig';
import LogisticService from '@LogisticService';
import { postRequestPreDeliveries } from './routesManagementSlice';
import { capitalizeAllFirstLetters } from '../../consumingApi/services/helper';
import { formatTextWithBold } from '../../utils/formatter';


const BASE_URL = appConfig.logisticsUrl;
const REDUCER_NAME = 'senninhaAnpreDeliveryFormgels';
const GET_VALIDATE_SERIAL = 'preDeliveryForm/getValidateSerial';
const GET_VALIDATE_TECHNICIAN = 'preDeliveryForm/getValidateTechnician';

const initialState = {
  serialValidationData: null,
  technicianValidationData: null,
  retryTechnicianWithLastPayload: null,
  statusValidateSerial: 'idle',
  statusValidateTechnician: 'idle',
  errorValidateSerial: null,
  errorValidateTechnician: null,
};

export const getValidateSerial = createAsyncThunk(GET_VALIDATE_SERIAL, async ({ terminal_entrada, os }, { rejectWithValue }) => {
  try {
    const res = await LogisticService.requestWithAuth(`${BASE_URL}/any/validar_terminal/${terminal_entrada}/${os}`);

    return res.data;
  } catch (err) {
    return rejectWithValue( err.response?.data);
  }
});

export const getValidateTechnician = createAsyncThunk(GET_VALIDATE_TECHNICIAN, async (data, { rejectWithValue, dispatch }) => {
  try {
    const { polo, tecnico, postRequestPreDeliveriesPayload } = data

    dispatch(setRetryTechnicianWithLastPayload(data))

    const res = await LogisticService.requestWithAuth(`${BASE_URL}/any/validar_tecnico/${polo}/${tecnico}`);

    if (postRequestPreDeliveriesPayload)
      dispatch(postRequestPreDeliveries(postRequestPreDeliveriesPayload))
    return res.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.mensagem || err.response.data || err.response || 'error');
  }
});

export const preDeliveryFormSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setRetryTechnicianWithLastPayload: (state, action) => {
      state.retryTechnicianWithLastPayload = action.payload;
    },
    resetValidationSerialStates: (state) => {
      state.serialValidationData = null;
      state.errorValidateSerial = null;
      state.statusValidateSerial = 'idle';
    },
    resetValidationTechnicianStates: (state) => {
      state.technicianValidationData = null;
      state.errorValidateTechnician = null;
      state.retryTechnicianWithLastPayload = null;
      state.statusValidateTechnician = 'idle';
    },
    resetPreDeliveryFormSlice: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getValidateSerial.pending, (state) => {
        state.statusValidateSerial = 'pending';
        state.errorValidateSerial = null;
      })
      .addCase(getValidateSerial.fulfilled, (state, action) => {
        state.serialValidationData = action.payload;
        state.statusValidateSerial = 'fulfilled';
      })
      .addCase(getValidateSerial.rejected, (state, action) => {
        state.serialValidationData = null;
        state.errorValidateSerial = action.payload;
        state.statusValidateSerial = 'rejected';
      })
      .addCase(getValidateTechnician.pending, (state) => {
        state.statusValidateTechnician = 'pending';
        state.errorValidateTechnician = null;
      })
      .addCase(getValidateTechnician.fulfilled, (state, action) => {
        state.technicianValidationData = action.payload;
        state.retryTechnicianWithLastPayload = null;
        state.statusValidateTechnician = 'fulfilled';
      })
      .addCase(getValidateTechnician.rejected, (state, action) => {
        state.technicianValidationData = null;
        state.errorValidateTechnician = action.payload;
        state.statusValidateTechnician = 'rejected';
      });
  },
});

export const {
  setRetryTechnicianWithLastPayload,
  resetValidationTechnicianStates,
  resetValidationSerialStates,
  resetPreDeliveryFormSlice
} = preDeliveryFormSlice.actions

export const preDeliveryFormSelector = (state) => state.preDeliveryFormSlice;

export default preDeliveryFormSlice.reducer;
