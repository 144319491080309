export const simulationListMockResponse = {
    "operadores_logisticos": [
        {
            "id_simulacao": "09bf4b8e-d728-4a50-87ab-9334104d21c6",
            "reference_key": "d2b4c0896c0e2d5e5d3feaf1d60b5fdd_0",
            "operador_logistico": "correios",
            "valor": 177.13,
            "endereco_entrega": {
                "address": "Rua Tavares Bastos",
                "zipCode": "22221030",
                "addressNumber": "5",
                "city": "RIO DE JANEIRO",
                "countryState": "RJ",
                "neighborhood": "Catete",
                "country": "BRASIL",
                "complement": null,
                "reference": null,
                "latitude": -22.9271149,
                "longitude": -43.1799879,
                "key": null,
                "accountId": null,
                "integrator": null,
                "nick": "teste stoninho",
                "phoneNumber": null,
                "postalCode": null,
                "referenceKey": null,
                "createdAt": null,
                "updatedAt": null
            }
        }
    ],
    "mais_barato": [
        {
            "id_simulacao": "7e6ea525-e913-45af-9d0c-6376fce51cb3",
            "reference_key": "d2b4c0896c0e2d5e5d3feaf1d60b5fdd_0",
            "operador_logistico": "correios",
            "valor": 177.13,
            "endereco_entrega": {
                "address": "Rua Tavares Bastos",
                "zipCode": "22221030",
                "addressNumber": "5",
                "city": "RIO DE JANEIRO",
                "countryState": "RJ",
                "neighborhood": "Catete",
                "country": "BRASIL",
                "complement": null,
                "reference": null,
                "latitude": -22.9271149,
                "longitude": -43.1799879,
                "key": null,
                "accountId": null,
                "integrator": null,
                "nick": "teste stoninho",
                "phoneNumber": null,
                "postalCode": null,
                "referenceKey": null,
                "createdAt": null,
                "updatedAt": null
            }
        }
    ],
    "simulacoes": [
        {
            "id_simulacao": "99312b47-7b55-46dc-b7c6-59b8b9735b83",
            "reference_key": "d2b4c0896c0e2d5e5d3feaf1d60b5fdd_0",
            "cliente": "Stoninho Teste",
            "operador_logistico": "loggiNational",
            "entregas": [
                {
                    "servico": "ENVIO DE SUPRIMENTOS",
                    "numero_os": "1354017",
                    "info_os": "teste sistemas - Referência: Não preenchido\nCriada por: Green App|du@stone.com.br|\nSistema De Origem: DASH_ANGELS - Alteração REPIQUE feita pelo green App |du@stone.com.br|",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": null,
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "192489630",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Avenida Oceânica, 1 - Rio Vermelho, SALVADOR - BA, 41950000"
                }
            ]
        },
        {
            "id_simulacao": "9de498b4-47a7-4c78-a27a-39828125901a",
            "reference_key": "d2b4c0896c0e2d5e5d3feaf1d60b5fdd_0",
            "cliente": "Stoninho",
            "operador_logistico": "loggiNational",
            "entregas": [
                {
                    "servico": "INSTALAÇÃO",
                    "numero_os": "1312435",
                    "info_os": "Observação: \nCriada por: Autosserviço\nSistema De Origem: Bot GPS - Fintalk",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": null,
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "169536475",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Rua das Marrecas 20, 20 - Cinelândia, RIO DE JANEIRO - RJ, 02278000"
                },
                {
                    "servico": "INSTALAÇÃO",
                    "numero_os": "1312436",
                    "info_os": "Observação: \nCriada por: Autosserviço\nSistema De Origem: Bot GPS - Fintalk",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": null,
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "169536475",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Rua das Marrecas 20, 20 - Cinelândia, RIO DE JANEIRO - RJ, 02278000"
                },
                {
                    "servico": "INSTALAÇÃO",
                    "numero_os": "1312437",
                    "info_os": "Observação: \nCriada por: Autosserviço\nSistema De Origem: Bot GPS - Fintalk",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": null,
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "169536475",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Rua das Marrecas 20, 20 - Cinelândia, RIO DE JANEIRO - RJ, 02278000"
                }
            ]
        },
        {
            "id_simulacao": "bc00a2c8-e630-48be-9878-7eef7cdaab3e",
            "reference_key": "STL-1316993",
            "cliente": "Nome do cliente para contato",
            "operador_logistico": "loggiNational",
            "entregas": [
                {
                    "servico": "INSTALAÇÃO",
                    "numero_os": "1316993",
                    "info_os": "OS DE TESTEEE!!! - Alteração REPIQUE feita pelo green App |du@stone.com.br|",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": null,
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "192489630",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Rua do passeio, 40 - Cinelândia, RIO DE JANEIRO - RJ, 20031120"
                }
            ]
        },
        {
            "id_simulacao": "7c7f00b0-9984-40d7-af3a-d0f2f0e75856",
            "reference_key": "STL-1313495",
            "cliente": "fellipe moura siqueira",
            "operador_logistico": "loggiNational",
            "entregas": [
                {
                    "servico": "TROCA",
                    "numero_os": "1313495",
                    "info_os": "Oferta: 162.0\nCriada por: GreenApp|boneves@stone.com.br\nSistema De Origem: DASH_ANGELS",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": "8601102149044531",
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "7351d5db-e39f-48f9-af6f-8cb1c81bd77c",
                    "contratante": "TON",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "Rua Riachuelo 291, 291 - Centro, RIO DE JANEIRO - RJ, 20230011"
                }
            ]
        },
        {
            "id_simulacao": "c8825ded-e6c7-475c-ae42-ee3c86bf818a",
            "reference_key": "STL-1352803",
            "cliente": "BRUNA",
            "operador_logistico": "loggiNational",
            "entregas": [
                {
                    "servico": "DESINSTALAÇÃO",
                    "numero_os": "1352803",
                    "info_os": "TESTE",
                    "valor_mais_barato": 0,
                    "terminal_entrada": null,
                    "terminal_saida": "192916913011169730862036",
                    "pos_recomendada": "GPRS-WIFI",
                    "id_atendimento": "564029727",
                    "contratante": "STONE PAGAMENTOS",
                    "previsao_entrega": "Hoje, às 09:30",
                    "endereco": "RUA SAO CLEMENTE, 188 - BOTAFOGO, RIO DE JANEIRO - RJ, 22260003"
                }
            ]
        }
    ],
    "endereco_polo": "R LAURO MEDEIROS, 19 -  LAGOA NOVA, NATAL - RN, 59075040",
    "contato_distrital": "(84)20303988"
}

export const createDeliveryMockRsponse = {
    data: [
      {
        "quoteId": "5270f743-aadf-4a8d-90c2-ae391fd70278",
        "referenceKey": "STL-1317128",
        // "erros_parciais_oss": [
        //     'OS 1111 - Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     'OS 2222 - Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     'OS 3333 - Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     'OS 4444 - Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // ]
      },
      {
        "quoteId": "5270f743-aadf-4a8d-90c2-ae391fd70278",
        "referenceKey": "STL-1317129",
      },
      {
        "quoteId": "5270f743-aadf-4a8d-90c2-ae391fd70278",
        "referenceKey": "STL-1317130",
        "idPolo": 267,
        // "erros_parciais_oss": [
        //     'OS 5555 - Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // ]
      }
    ]
}

export const trackingDeliveriesMockResponseOld = {
  data: [
    {   
        uuid: '123e4567-e89b-12d3-a456-426614174000',
        id_simulacao: '14543985350',
        id_atendimento: '167130850',
        angel: 'boneves@stone.com.br',
        cliente: 'Sheldon biscoitos lta',
        operador_logistico: 'loggi',
        oss: [
            {
                os: 2325561,
                servico: 'TROCA'
            },
            {
                os: 2324062,
                servico: 'INSTALACAO'
            },
        ],
        status: {
            tipo: 'error',
            mensagem: 'ineficiencia'
        },
    },
    {
        uuid: '123e4567-e89b-12d3-a456-426614174001',
        id_simulacao: '14543985351',
        id_atendimento: '167130851',
        angel: 'boneves@stone.com.br',
        cliente: 'Padaria do Cadu',
        operador_logistico: 'flash',
        oss: [
            {
                os: 2325573,
                servico: 'TROCA'
            },
            {
                os: 2324074,
                servico: 'INSTALACAO'
            },
        ],
        status: {
            tipo: 'info',
            mensagem: 'em coleta'
        },
    },
    {
        uuid: '123e4567-e89b-12d3-a456-426614174001',
        id_simulacao: '14543985352',
        id_atendimento: '167130852',
        angel: 'boneves@stone.com.br',
        cliente: 'Padaria do Joao',
        operador_logistico: 'Uber',
        oss: [
            {
                os: 2325575,
                servico: 'SUPRIMENTO'
            },
            {
                os: 2324076,
                servico: 'DESINSTALACAO'
            },
        ],
        status: {
            tipo: 'info',
            mensagem: 'em coleta'
        },
    },
  ]
}


export const trackingDeliveriesMockResponse = {
    data: [
        {
            "reference_key": "STL-1317128",
            "status": {
                "tipo": "REJECTED",
                "mensagem": "REJECTED"
            },
            "previsao_entrega": "Hoje, às 15:59",
            "cliente": "Stoninho1",
            "endereco": null,
            "contato": "219883311111",
            "contratante": "Stone Logistica",
            "operador_logistico": "loggiNational",
            "tracking_code": "STN038BE1E",
            "id_atendimento": "136687519",
            "oss": [
                {
                    "id_os": 1317128,
                    "servico": "DESINSTALAÇÃO",
                    "terminal_entrada": null,
                    "terminal_saida": "6C0923354"
                }
            ],
            "documento_cliente": "123412341234",
            "estabelecimento": "Stoninho1",
            "observacao": null
        },
        {
            "reference_key": "STL-1317129",
            "status": {
                "tipo": "REJECTED",
                "mensagem": "REJECTED"
            },
            "previsao_entrega": "Hoje, às 15:59",
            "cliente": "Stoninho2",
            "endereco": null,
            "contato": "219883311111",
            "contratante": "Stone Logistica",
            "operador_logistico": "loggiNational",
            "tracking_code": "STN038BE1E",
            "id_atendimento": "136687519",
            "oss": [
                {
                    "id_os": 1317129,
                    "servico": "DESINSTALAÇÃO",
                    "terminal_entrada": null,
                    "terminal_saida": "6C092645354"
                }
            ],
            "documento_cliente": "123412341234",
            "estabelecimento": "Stoninho2",
            "observacao": null
        },
        {
            "reference_key": "STL-1317130",
            "status": {
                "tipo": "REJECTED",
                "mensagem": "REJECTED"
            },
            "previsao_entrega": "Hoje, às 15:59",
            "cliente": "Stoninho3",
            "endereco": null,
            "contato": "219883311111",
            "contratante": "Stone Logistica",
            "operador_logistico": "loggiNational",
            "tracking_code": "STN038BE1E",
            "id_atendimento": "136687519",
            "oss": [
                {
                    "id_os": 1317130,
                    "servico": "DESINSTALAÇÃO",
                    "terminal_entrada": null,
                    "terminal_saida": "6C098672354"
                }
            ],
            "documento_cliente": "123412341234",
            "estabelecimento": "Stoninho3",
            "observacao": null
        }
    ]
  }

export const trackingDeliveriesPreBaixadaMockResponse = {
    data: [
        {
            "reference_key": "64224c11de4f50574671adf8eaaee56f_0",
            "status": {
                "mensagem": "Rejeitada",
                "tipo": "error"
            },
            "previsao_entrega": null,
            "cliente": "Stoninho",
            "endereco": "Rua Misteriosa, 1 - Catete, RIO DE JANEIRO - RJ, 22221011",
            "contato": "21999793087",
            "contratante": "Stone Logistica",
            "operador_logistico": "correios",
            "tracking_code": null,
            "id_atendimento": "392064753",
            "oss": [
                {
                    "id_os": 1315289,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "523-996-053",
                    "terminal_saida": null
                }
            ],
            "documento_cliente": "123412341234",
            "estabelecimento": "Stoninho",
            "observacao": "Entregar 1 máquina.",
            "horario_finalizado": "2024-09-24T14:32:45Z"
        },
        {
            "reference_key": "e52fac0696ebba02c828b56479052fd0_0",
            "status": {
                "mensagem": "Rejeitada",
                "tipo": "error"
            },
            "previsao_entrega": null,
            "cliente": "teste stoninho",
            "endereco": "Rua Tavares Bastos, 5 - Catete, RIO DE JANEIRO - RJ, 22221030",
            "contato": "21999793030",
            "contratante": "Stone Logistica",
            "operador_logistico": "correios",
            "tracking_code": null,
            "id_atendimento": "598224280",
            "oss": [
                {
                    "id_os": 1354158,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "3456787568987",
                    "terminal_saida": null
                },
                {
                    "id_os": 1354160,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "523-697-262",
                    "terminal_saida": null
                },
                {
                    "id_os": 1354161,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "523-697-221",
                    "terminal_saida": null
                },
                {
                    "id_os": 1354162,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "523-868-377",
                    "terminal_saida": null
                },
                {
                    "id_os": 1354159,
                    "servico": "INSTALAÇÃO",
                    "terminal_entrada": "523-868-503",
                    "terminal_saida": null
                }
            ],
            "documento_cliente": "999.999.999-99",
            "estabelecimento": "teste stoninho",
            "observacao": "Entregar 5 máquinas ",
            "horario_finalizado": "2024-09-24T14:32:45Z"
        }
    ]
}


export const preBaixadasFinalizadas = {
    data: [
      {
          "reference_key": "e52fac0696ebba02c828b56479052fd0_0",
          "status": {
              "mensagem": "Rejeitada",
              "tipo": "error",
              "cancelavel": false
          },
          "previsao_entrega": "Hoje, às 14:12",
          "cliente": "teste stoninho",
          "endereco": "Rua Tavares Bastos, 5 - Catete, RIO DE JANEIRO - RJ, 22221030",
          "contato": "21999793030",
          "contratante": "Stone Logistica",
          "operador_logistico": "correios",
          "tracking_code": null,
          "id_atendimento": "598224280",
          "oss": [
              {
                  "id_os": 1354158,
                  "servico": "INSTALAÇÃO",
                  "terminal_entrada": "3456787568987",
                  "terminal_saida": null
              },
              {
                  "id_os": 1354160,
                  "servico": "INSTALAÇÃO",
                  "terminal_entrada": "523-697-262",
                  "terminal_saida": null
              },
              {
                  "id_os": 1354161,
                  "servico": "INSTALAÇÃO",
                  "terminal_entrada": "523-697-221",
                  "terminal_saida": null
              },
              {
                  "id_os": 1354162,
                  "servico": "INSTALAÇÃO",
                  "terminal_entrada": "523-868-377",
                  "terminal_saida": null
              },
              {
                  "id_os": 1354159,
                  "servico": "INSTALAÇÃO",
                  "terminal_entrada": "523-868-503",
                  "terminal_saida": null
              }
          ],
          "documento_cliente": "999.999.999-99",
          "estabelecimento": "teste stoninho",
          "observacao": "Entregar 5 máquinas ",
          "horario_finalizado": "2024-09-24T14:32:45Z"
      },
      {
        "reference_key": "e52fac069222a02c828b56479052fd0_1",
        "status": {
            "mensagem": "Rejeitada",
            "tipo": "error",
            "cancelavel": false
        },
        "previsao_entrega": "Hoje, às 14:12",
        "cliente": "teste stoninho",
        "endereco": "Rua Tavares Bastos, 5 - Catete, RIO DE JANEIRO - RJ, 22221030",
        "contato": "21999793030",
        "contratante": "Stone Logistica",
        "operador_logistico": "correios",
        "tracking_code": null,
        "id_atendimento": "598224280",
        "oss": [
            {
                "id_os": 1354160,
                "servico": "INSTALAÇÃO",
                "terminal_entrada": "523-868-599",
                "terminal_saida": null
            }
        ],
        "documento_cliente": "999.999.999-99",
        "estabelecimento": "teste 123",
        "observacao": "Entregar 1 máquina ",
        "horario_finalizado": "2024-09-26T08:01:45Z"
    }
  ]
  }

export const getValidateTechnicianResponse = {
    data: {
      tipo: 'success',
      titulo: 'Técnico válido',
      mensagem: 'Técnico validado com sucesso!'
    }
  }