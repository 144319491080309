import styled from "styled-components";

export const Paginator = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  gap: 16px;

  p {
    color: #303742;
    font-size: 13px;
    line-height: 18.85px;
    letter-spacing: -0.01em;
    height: 20px;
    margin-top: 2px;
    user-select: none;
  }
`;
