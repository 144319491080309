import React, { useEffect } from "react";
import { Button, Drawer, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { postTrackingDeliveryPreBaixada, trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeAllFirstLetters } from "../../../../../../../consumingApi/services/helper";
import { format, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";

import * as S from "./styles";


const DetailsDeliveryPreBContent = ({ open, onClose, data }) => {
    const {
        statusPostDeliveriesPreBaixada
    } = useSelector(trackingDeliveriesSelector)

    const dispatch = useDispatch()

    const countItems = (items) => {
        const counts = items.reduce((data, item) => {
            data[item] = (data[item] || 0) + 1;
            return data;
        }, {});

        return Object.keys(counts).map((key) => ({
            name: key,
            amount: counts[key]
        }));
    }

    const getServicesFormatted = (services) => {
        const aux = services.map(({ servico }) => servico)
        if (!aux) return ''

        return aux.length === 1 ?
            capitalizeAllFirstLetters(aux[0])
            : countItems(aux).map(({ name, amount }) => 
                `${capitalizeAllFirstLetters(name)} (${amount})`
              ).toString().replace(',', ', ')
    }

    const getOSsFormatted = (oss) => {
        const aux = oss.map(({ id_os }) => id_os)
        if (!aux) return ''

        return aux.length === 1 ?
            aux[0]
            : aux.toString().replaceAll(',', ', ')
    }

    const handleBaixaOs = () => {
        const payload = data?.oss.map((item) => ({
            id_os: item.id_os,
            servico: item.servico,
            terminal_entrada: item.terminal_entrada,
            // terminal_saida: item.terminal_saida
        }))

        dispatch(postTrackingDeliveryPreBaixada({ 
            refIds: [data.reference_key], 
            payload 
        }))        
    }

    const formatDate = (date) => {
        if (isToday(date))
            return `Hoje, às ${format(date, "HH:mm", { locale: ptBR })}`;
        return `dia ${format(date, "d, 'às' HH:mm", { locale: ptBR })}`;
    };

    useEffect(() => {
        if (statusPostDeliveriesPreBaixada === 'fulfilled')
            onClose()
    }, [statusPostDeliveriesPreBaixada])

    return (
        <>
            <Drawer.Body>
                {data &&
                    <S.AllDeliveryDrawerContent>
                        <S.AllBodyContainer>
                            <S.BodyTopContainer>
                                <S.BodyTopContent>
                                    <p>
                                        Status
                                    </p>
                                    <span>
                                        <Tag
                                            text={capitalizeAllFirstLetters(data.status.mensagem)}
                                            type={data.status.tipo}
                                        />
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Previsão de entrega
                                    </p>
                                    <span>
                                        {data?.horario_finalizado ? formatDate(new Date(data.horario_finalizado)) 
                                            : 'Horário não informado'
                                        }
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Cliente
                                    </p>
                                    <span>
                                        {data.cliente}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Contato
                                    </p>
                                    <span>
                                        {data.contato}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Endereço
                                    </p>
                                    <span>
                                        {data.endereco}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Contratante
                                    </p>
                                    <span>
                                        {data.contratante}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Stonecode
                                    </p>
                                    <span>
                                        {data.id_atendimento}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Grupo de serviço
                                    </p>
                                    <span>
                                        {getServicesFormatted(data.oss)}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Nº da OS
                                    </p>
                                    <span>
                                        {getOSsFormatted(data.oss)}
                                    </span>
                                </S.BodyTopContent>
                                <S.BodyTopContent>
                                    <p>
                                        Operador logístico
                                    </p>
                                    <span>
                                        {capitalizeAllFirstLetters(data.operador_logistico)}
                                    </span>
                                </S.BodyTopContent>
                            </S.BodyTopContainer>
                        </S.AllBodyContainer>
                    </S.AllDeliveryDrawerContent>
                }
            </Drawer.Body>

            <Drawer.Footer>
                <Button
                    type='primary'
                    onClick={handleBaixaOs}
                    loading={statusPostDeliveriesPreBaixada === 'pending'}
                >
                    Dar baixa na OS
                </Button>

                <Button
                    type='neutral'
                    style={{ marginLeft: 8 }}   
                    disabled
                >
                    <GingaIcon
                        name="warning"
                        color="#767F8D"
                        size={20}
                        style={{ cursor: 'pointer' }}
                    />
                    Reportar erro
                </Button>
            </Drawer.Footer>
        </>
    )
}

export default DetailsDeliveryPreBContent;
