import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
    getServiceDetails, pinsSelector,
    setMapWorkflow, setOpenDrawer, setBouncePin
} from "../../../../../../features/senninha/pinsSlice";
import { useDispatch, useSelector } from "react-redux";
import HoverContainer from "./HoverContainer";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { hubsSelector } from "../../../../../../features/senninha/hubsSlice";
import { manageSelectedRelocatePins, routesManagementSelector } from "../../../../../../features/senninha/routesManagementSlice";
import { toCapitalizeFirstLetterOfEachWord } from "../../../../../../consumingApi/services/helper";
import { setUnassociatedPinAnyData } from "../../../../../../features/senninha/routesManagementSlice";
import { setHunterPinClicked, setOldSenninhaWorkFlow } from "../../../../../../features/senninha/oldSenninhaSlice";
import "./styles.css";

import * as S from "./styles";


const Marker = ({
    idx,
    map,
    position,
    dataPin
}) => {
    const rootRef = useRef();
    const markerRef = useRef();

    const [hoverMarker, setHoverMarker] = useState(false)

    const dispatch = useDispatch()

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        selectedRelocatePins
    } = useSelector(routesManagementSelector);

    const {
        pins,
        mapWorkflow,
        openDrawer,
        serviceDetails,
        bouncedPin,
    } = useSelector(pinsSelector);

    useEffect(() => {
        if (dataPin.id === bouncedPin) {
            setTimeout(() => {
                dispatch(setBouncePin(''))
            }, 2000)
        }
    }, [bouncedPin]);

    const handleMarkerClick = () => {
        if (mapWorkflow === 'infoDrawer' && dataPin?.servico !== '0TPV') {
            //da dispatch no detalhe do pin clicado
            dispatch(
                getServiceDetails({
                    id: dataPin.id,
                    id_atendimento: dataPin?.id_atendimento,
                    provider: selectedHubInfos.children,
                    servico: dataPin.servico,
                    id_os: dataPin.oss[0].id_os
                })
            )
            dispatch(
                setOpenDrawer(true),
                setMapWorkflow('infoDrawer')
            )
        }

        if (dataPin?.servico === '0TPV') {
            dispatch(setOldSenninhaWorkFlow('pinHunter'))
            dispatch(setHunterPinClicked(dataPin))
        }

        if (mapWorkflow === 'routesDrawer-relocation') {
            const selectedCollectPins = pins.filter((item) =>
                item.associatedPinsIDs && selectedRelocatePins.find((item2) => item2.id === item.id)
            )
            const collectPinsUnallocated = selectedCollectPins.filter(({ associatedPinsIDs }) =>
                !selectedRelocatePins.find((item) => associatedPinsIDs.includes(item.id)
            ))

            // regras auxiliares
            const hasNoSelectedPins = !selectedRelocatePins.length
            const hasUnallocatedCollectPin = !!collectPinsUnallocated.length && selectedCollectPins.length
            const clickedPinAlreadySelected = !!selectedRelocatePins.find((item) => item.id === dataPin.id)
            const clickedAdqPin = dataPin.tipo?.toLowerCase() !== 'any'
            const clickedCollectPin = dataPin.servico?.toLowerCase() === 'coleta'
            const clickedDeliveryPin = dataPin.servico?.toLowerCase() === 'entrega'
            const associatedWithCollectPin = !!selectedCollectPins.find((item) => item.associatedPinsIDs?.includes(dataPin.id))

            // regras pins adq
            const allowSelectAdqPin = clickedAdqPin && !hasUnallocatedCollectPin

            // regras pins coleta
            const hasUnallocatedCollectPinAndClickedCollect = clickedCollectPin && !hasUnallocatedCollectPin

            // regras pins entrega
            const isDeliveryPinFromUnallocatedCollectPin = clickedDeliveryPin && hasUnallocatedCollectPin && associatedWithCollectPin
            const hasUnallocatedDeliveryPinAndClickedDelivery = clickedDeliveryPin && !hasUnallocatedCollectPin
            const shouldSelectCollectPinAssociated = clickedDeliveryPin && !clickedPinAlreadySelected && !associatedWithCollectPin
            if (
                hasNoSelectedPins
                || allowSelectAdqPin
                || hasUnallocatedCollectPinAndClickedCollect
                || isDeliveryPinFromUnallocatedCollectPin
                || hasUnallocatedDeliveryPinAndClickedDelivery
                || (clickedPinAlreadySelected && !clickedAdqPin)
            ) {
                dispatch(manageSelectedRelocatePins(dataPin))
                if (shouldSelectCollectPinAssociated) {
                    const associatedCollectPin2BeSelected = pins.find((item) => item?.associatedPinsIDs?.includes(dataPin.id))
                    if (associatedCollectPin2BeSelected) {
                        dispatch(manageSelectedRelocatePins(associatedCollectPin2BeSelected))
                        dispatch(setUnassociatedPinAnyData({
                            unassociatedPin: associatedCollectPin2BeSelected,
                            deliveriesPins2BeAssociated: 
                                pins.filter((item) => associatedCollectPin2BeSelected?.associatedPinsIDs.includes(item.id)),
                        }))
                    }
                }
            }
        }
    };

    const label = {
        'DESINSTALAÇÃO': 'Desin.',
        'ENVIO DE CARTAO': 'Env. Cart.',
        'ENVIO DE SUPRIMENTOS': 'Supri.',
        'INSTALAÇÃO': 'Inst.',
        'MANUTENÇÃO': 'Manut.',
        'TROCA': 'Troca',
        'COLETA': 'Coleta',
        'ENTREGA': 'Entrega',
        'CAMPANHA': 'Camp.'
    }

    const CustomMarker = () => {
        const isSelected = selectedRelocatePins.find((item) => item.id === dataPin?.id)
        const isBeingCheckedInInfoDrawer = mapWorkflow.includes('infoDrawer') && openDrawer

        return (
            <S.MarkerContainer
                onMouseEnter={() => { setHoverMarker(true); markerRef.current.zIndex = (position.lat * -1000000) << 5; }}
                onMouseLeave={() => { setHoverMarker(false); markerRef.current.zIndex = (position.lat * -100000) << 5; }}
                background={isBeingCheckedInInfoDrawer ? 
                    (serviceDetails?.pinIdentifiers?.id === dataPin.id ? "#0057D9" :"#D0D5DD") 
                    : dataPin?.stylePin?.color
                }
                isSelected={isSelected}
                className={dataPin?.id === bouncedPin && ('bounce')}
            >
                <S.MarkerLeftContent>
                    <GingaIcon name={dataPin?.stylePin.name} size={'12px'} color="white" />
                </S.MarkerLeftContent>
                <S.MarkerRightContent>
                    {dataPin?.servico ? (label[dataPin?.servico] ?? toCapitalizeFirstLetterOfEachWord(dataPin?.servico)) : ''}
                </S.MarkerRightContent>
                {hoverMarker && (
                    <HoverContainer dataPin={dataPin} />
                )}
                {
                    dataPin?.servico === '0TPV' && dataPin?.ineficiencia !== null && ( // QUANDO O PIN FOR HUNTER JA COM INEFICIENCIA PREENCHIDO
                        <S.MarkerLeftContent>
                            <GingaIcon name={'warning'} size={'12px'} color="white" />
                        </S.MarkerLeftContent>
                    )
                }
            </S.MarkerContainer>
        )
    }

    //useEffect com função de renderizar os pins
    useEffect(() => {
        if (!rootRef.current) {
            const container = document.createElement("div");
            rootRef.current = createRoot(container);

            markerRef.current = new google.maps.marker.AdvancedMarkerElement({
                position,
                content: container,
                // Quando renderizar os pins ele vai dar prioridade de visualização
                // ao pin com latitude mais proxima do hemisfério sul 
                zIndex: (position.lat * -100000) << 5
            })

            return () => (markerRef.current.map = null);
        }
    }, []);

    //useEffect com a função de controlar os eventos nos pins
    useEffect(() => {
        rootRef.current.render(CustomMarker());
        markerRef.current.position = position;
        markerRef.current.map = map;
        const listener = markerRef.current.addListener("click", handleMarkerClick);
        return () => listener.remove();
    }, [map, position, hoverMarker, selectedRelocatePins]);

    return null;
}

export default Marker
