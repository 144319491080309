import * as Yup from 'yup';

export const installationSchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  inputSerial: Yup.string().required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})

export const eventSchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  inputSerial: Yup.string().required('Campo obrigatório'),
  outputSerial: Yup.string().required('Campo obrigatório'),
  defects: Yup.string().required('Campo obrigatório'),
  solutions: Yup.string().required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})

export const exchangeSchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  inputSerial: Yup.string().required('Campo obrigatório'),
  outputSerial: Yup.string().required('Campo obrigatório'),
  defects: Yup.string().required('Campo obrigatório'),
  solutions: Yup.string().required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})

export const maintenanceSchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  inputSerial: Yup.string().required('Campo obrigatório'),
  outputSerial: Yup.string().required('Campo obrigatório'),
  defects: Yup.string().required('Campo obrigatório'),
  solutions: Yup.string().required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})

export const supplySchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  supllyKit: Yup.string().required('Campo obrigatório'),
  supllyKitAmount: Yup.string().test(
      'wei-not-zero', 
      'O peso não pode ser zero', 
      (value) => parseFloat(value) !== 0
    ).required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})

export const uninstallationSchema = (isInMultiGroupForm) => Yup.object().shape({
  osInfo: Yup.string().required('Campo obrigatório'),
  outputSerial: Yup.string().required('Campo obrigatório'),
  ...(isInMultiGroupForm ? {} : { notes: Yup.string().required('Campo obrigatório') })
})
