import React, { useState, useEffect, useRef } from "react";
import {
    GoogleMap,
    Marker,
} from "@react-google-maps/api";
import { personIconSvg } from "./../../../../../../../assets/icons/DeliveryTrackingIcon";

import LogisticService from "../../../../../../../consumingApi/services/LogisticService";


const containerStyle = {
    width: "100%",
    height: "209px",
    marginBottom: "16px"
};


const DetailsDeliveryContentTracking = ({ isOpen, trackingCode }) => {

    const [state, setState] = useState();
    const trackingPullingRef = useRef(null); // Armazena o intervalo de pulling

    const loadTrackingDetails = async () => {
        const tracking = await LogisticService.getTrackingDelivery(trackingCode);
        setState(tracking);
    };

    useEffect(() => {
        loadTrackingDetails();

        return () => {
            console.log('[DeliveryUpdate] [Pulling] End ...');
            if (trackingPullingRef.current) {
                clearInterval(trackingPullingRef.current);
                trackingPullingRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (isOpen === true && state?.data?.courier) {
            if (!trackingPullingRef.current) {
                console.log('Iniciando pulling para tracking');
                trackingPullingRef.current = setInterval(() => {
                    console.log('[DeliveryUpdate] [Pulling] Get Tracking ');
                    loadTrackingDetails();
                }, 10000);
            }
        } else {
            if (trackingPullingRef.current) {
                clearInterval(trackingPullingRef.current);
                trackingPullingRef.current = null;
            }
        }
    }, [isOpen, state?.data?.status]);

    return (isOpen && state?.data?.courier) && (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: state.data.courier.location.latitude, lng: state.data.courier.location.longitude }}
                zoom={15}
                clickableIcons={false}
                on
                options={{
                    disableDefaultUI: true,
                    zoomControl: false,
                    keyboardShortcuts: false,
                    panControlOptions: false
                }}
            >
                <Marker
                    key={1}
                    position={{ lat: state.data.courier.location.latitude, lng: state.data.courier.location.longitude }}
                    icon={{
                        url: `data:image/svg+xml;base64,${btoa(personIconSvg)}`,
                        scaledSize: new window.google.maps.Size(27, 27),
                    }}
                >
                </Marker>
            </GoogleMap>
        </>
    );
};

export default DetailsDeliveryContentTracking;