import React, { useEffect, useState } from "react";
import { Drawer } from "@stonelog/stonelog-design-system";
import ProgressContent from "./ProgressContent";
import FinishedContent from "./FinishedContent";
import { useSelector, useDispatch } from "react-redux";
import { getTrackingDeliveries, getTrackingDeliveriesPreBaixada, trackingDeliveriesSelector } from "../../../../../../../features/senninha/trackingDeliveriesSlice";
import { hubsSelector } from "../../../../../../../features/senninha/hubsSlice";


const DeliveryDrawer = ({ onOpenDeliveryDrawer, onOpenDeliveryPreBaixadaDrawer }) => {
    const [activeKey, setActiveKey] = useState('1')

    const {
        deliveries,
        statusGetDeliveries,
        deliveriesPreBaixada,
        statusGetDeliveriesPreBaixada
    } = useSelector(trackingDeliveriesSelector)
    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const dispatch = useDispatch();  

    const onChangeTabs = (key) => {
        setActiveKey(key)
        // if (onOpenDeliveryDrawer && key === '1' && statusGetDeliveries !== 'pending')
        //     dispatch(getTrackingDeliveries(selectedHubInfos.children))
        // if (onOpenDeliveryDrawer && key === '2' && statusGetDeliveriesPreBaixada !== 'pending')
        //     dispatch(getTrackingDeliveriesPreBaixada(selectedHubInfos.children))
    }

    return (
        <Drawer.Tabs
            defaultActiveKey={activeKey}
            items={[
                {
                    key: '1',
                    label: `Em andamento (${deliveries?.length || 0})`,
                    children: (
                        <ProgressContent
                            onOpenDeliveryDrawer={onOpenDeliveryDrawer}
                        />
                    ),
                },
                {
                    key: '2',
                    label: `Finalizadas hoje (${deliveriesPreBaixada?.length || 0})`,
                    children: (
                        <FinishedContent
                            onOpenDeliveryPreBaixadaDrawer={onOpenDeliveryPreBaixadaDrawer}
                        />
                    )
                },
            ]}
            onChange={onChangeTabs}
        />
    )
}

export default DeliveryDrawer;
