import { useEffect, useRef, useState } from "react";
import { Drawer } from "@stonelog/stonelog-design-system";
import FilterDrawer from "./FilterDrawer";
import LegendDrawer from "./LegendDrawer";
import SearchDrawer from "./SearchDrawer";
import DeliveryDrawer from "./DeliveryDrawer";
import DetailsDeliveryContent from "./DeliveryDrawer/DetailsDeliveryContent";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { routesManagementSelector, setFlagOpenTrackDeliveries } from "../../../../../../features/senninha/routesManagementSlice";
import { useSelector, useDispatch } from "react-redux";
import { hubsSelector } from "../../../../../../features/senninha/hubsSlice";
import DetailsDeliveryPreBContent from "./DeliveryDrawer/DetailsDeliveryPreBContent";
import { pinsSelector, setBuildFilters } from "../../../../../../features/senninha/pinsSlice";
import { getTrackingDeliveries } from "../../../../../../features/senninha/trackingDeliveriesSlice";

import * as S from "./styles";


const LeftMenuDrawers = ({
    open,
    setOpen,
    setDrawerSelected,
    drawerSelected
}) => {
    const [detailsDeliveryDrawer, setDetailsDeliveryDrawer] = useState(false)
    const [deliveryDetails, setDeliveryDetails] = useState()
    const [detailsDeliveryPreBDrawer, setDetailsDeliveryPreBDrawer] = useState(false)
    const [deliveryPreBaixadaDetails, setDeliveryPreBaixadaDetails] = useState()

    const { flagOpenTrackDeliveries } = useSelector(routesManagementSelector);
    const { pins, leftOpenDrawer } = useSelector(pinsSelector);

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const dispatch = useDispatch();

    const titleToggler = {
        'legend': 'Legenda',
        'filter': 'Filtrar por',
        'search': 'Buscar por',
        'delivery': 'Deliveries'
    }

    const onOpenDeliveryDrawer = (deliveryData) => {
        setDeliveryDetails(deliveryData)
        setDetailsDeliveryDrawer(true)
    }

    const onOpenDeliveryPreBaixadaDrawer = (deliveryData) => {
        setDeliveryPreBaixadaDetails(deliveryData)
        setDetailsDeliveryPreBDrawer(true)
    }

    useEffect(() => {
        if (flagOpenTrackDeliveries) {
            setDrawerSelected('delivery')
            setOpen(true)
            dispatch(setFlagOpenTrackDeliveries(false))
        }
    }, [flagOpenTrackDeliveries])

    useEffect(() => {
        if (pins) {
            const uniqueAngels = [];
            pins.forEach(pin => {
                if (!uniqueAngels.includes(pin.angel)) {
                    uniqueAngels.push(pin.angel);
                }
            });

            // Remove "Hunter TON" and "Hunter TON (Com ineficiência)" from uniqueAngels array
            const filteredAngels = uniqueAngels.filter(
                angel => angel !== "Hunter TON" &&
                    angel !== "Hunter TON (Com ineficiência)" &&
                    angel !== "Hunter STONE" &&
                    angel !== "Hunter STONE (Com ineficiência)"
            );

            if (leftOpenDrawer === false) {
                dispatch(setBuildFilters(filteredAngels.map(angel => ({ field: 'angel', value: angel, drawer: 'legenda' }))));
            }
        }
    }, [pins]);

    return (

        <S.InsideDrawerContent>
            <Drawer.Root
                title={
                    <Drawer.Header
                        title={titleToggler[drawerSelected]}
                        subTitle={titleToggler[drawerSelected] === "Deliveries" ? "Você pode acompanhar detalhes de cada delivery clicando sobre ela." : ''}
                    />
                }
                placement="left"
                open={open}
                getContainer={false}
                onClose={() => setOpen(false)}
                closeIcon={null}
            >
                {
                    drawerSelected === 'legend' && (
                        <LegendDrawer />
                    )
                }
                {
                    drawerSelected === 'filter' && (
                        <FilterDrawer />
                    )
                }
                {
                    drawerSelected === 'search' && (
                        <SearchDrawer />
                    )
                }
                {
                    drawerSelected === 'delivery' && (
                        <DeliveryDrawer
                            onOpenDeliveryDrawer={onOpenDeliveryDrawer}
                            onOpenDeliveryPreBaixadaDrawer={onOpenDeliveryPreBaixadaDrawer}
                        />
                    )
                }
            </Drawer.Root>

            <Drawer.Root
                placement="left"
                open={detailsDeliveryDrawer}
                getContainer={false}
                closeIcon={null}
                title={
                    <Drawer.Header
                        subTitle={
                            <S.ButtonStyled
                                solid
                                size='small'
                                width="36px"
                                onClick={() => {
                                    // dispatch(getTrackingDeliveries(selectedHubInfos.children))
                                    setDetailsDeliveryDrawer(false)
                                }}
                            >
                                <GingaIcon name="chevron-left" color='black' />
                            </S.ButtonStyled>
                        }
                    />
                }
            >
                <DetailsDeliveryContent
                    open={detailsDeliveryDrawer}
                    onClose={() => {
                        setDetailsDeliveryDrawer(false)
                    }}
                    data={deliveryDetails}
                />
            </Drawer.Root>

            <Drawer.Root
                placement="left"
                open={detailsDeliveryPreBDrawer}
                getContainer={false}
                closeIcon={null}
                title={
                    <Drawer.Header
                        subTitle={
                            <S.ButtonStyled
                                solid
                                size='small'
                                width="36px"
                                onClick={() => setDetailsDeliveryPreBDrawer(false)}
                            >
                                <GingaIcon name="chevron-left" color='black' />
                            </S.ButtonStyled>
                        }
                    />
                }
            >
                <DetailsDeliveryPreBContent
                    open={detailsDeliveryPreBDrawer}
                    onClose={() => {
                        setDetailsDeliveryPreBDrawer(false)
                    }}
                    data={deliveryPreBaixadaDetails}
                />
            </Drawer.Root>
        </S.InsideDrawerContent>
    )
}

export default LeftMenuDrawers;
